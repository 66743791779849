import { Dispatch, ReactNode, createContext, useContext, useState } from "react";

interface TokenContextType {
  token: string;
  setToken: Dispatch<string>;
}

const TokenContext = createContext<TokenContextType | undefined>(undefined);

interface TokenProviderProps {
  children: ReactNode;
}

export const TokenProvider = ({ children }: TokenProviderProps) => {
  const [token, setToken] = useState<string>('');

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useTokenContext = (): TokenContextType => {
  const context = useContext(TokenContext);

  if (!context) {
    throw new Error('useFilter must be used within a TokenProvider');
  }

  return context;
};
