import dayjs from "dayjs";
import { useQuery } from "react-query";
import { http } from "../../useAxios";
import { VideoInputOutputDTO } from "../../../models/VideoInputOutputDTO";

export async function getListVideos(
  veiculoId: string,
  dataInicialDoVideo: string,
  token: string,
): Promise<VideoInputOutputDTO[]> {
  if (!veiculoId || !dataInicialDoVideo) {
    return [];
  }

  const initialDateInDayJS = dayjs(dataInicialDoVideo);

  const response = await http.get<Blob>(
    `Videos/ListarVideos/${
      veiculoId
    }/${
      initialDateInDayJS.format("YYYY-MM-DD")
    }`,
    {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const textData = await response.data.text();
  const parsedData = JSON.parse(textData) as VideoInputOutputDTO[];

  return parsedData;
}

export function useListVideos(
  veiculoId: string,
  dataInicialDoVideo: string,
  dataFinalDoVideo: string,
  token: string,
) {
  const start = dataInicialDoVideo;
  const final = dataFinalDoVideo || dataInicialDoVideo;

  return useQuery({
    queryKey: [`vehicles-videos-by-range-${veiculoId}-${start}-${final}`],
    queryFn: () =>
      getListVideos(
        veiculoId,
        dataInicialDoVideo,
        token,
      ),
    staleTime: 1000 * 60 * 60, // 1 hora
    enabled: !!veiculoId && !!dataInicialDoVideo && !!token,
  });
}
