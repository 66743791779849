import { padNumber } from "@/utils/padNumber";

export function formatNumberToTime(seconds: number): string {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = padNumber(`${date.getUTCSeconds()}`);

  if (hh) {
    return `${hh}:${padNumber(`${mm}`)}:${ss}`;
  }

  return `00:${mm}:${ss}`;
};
