import { useQuery } from "react-query";

import { http } from "../../useAxios";
import { VideoInputOutputDTO } from "../../../models/VideoInputOutputDTO";

export async function getGenerateLinks(
  selectedVideo: VideoInputOutputDTO | null,
  token: string,
): Promise<VideoInputOutputDTO> {
  if (!selectedVideo) {
    return {} as VideoInputOutputDTO;
  }

  try {
    const response = await http.post<Blob>(
      '/Videos/GeraLinks',
      selectedVideo,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const textData = await response.data.text();
    const parsedData = JSON.parse(textData) as VideoInputOutputDTO;

    return parsedData;
  } catch (error) {
    console.error(error);

    return {} as VideoInputOutputDTO;
  }
};

export function useGenerateLinks(
  selectedVideo: VideoInputOutputDTO | null,
  token: string,
) {
  return useQuery(
    `videos-links-${selectedVideo?.id}`,
    () => getGenerateLinks(selectedVideo, token),
    {
      staleTime: 1000 * 60 * 60,
      enabled: true,
    },
  );
};
