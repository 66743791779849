import { FormEvent, MutableRefObject } from "react";
import ReactPlayer from "react-player";
import { VideoIcon, DownloadIcon, MaximizeIcon, MinimizeIcon } from "lucide-react";

import { getGenerateClip, getVideosDownload } from "@/services/hooks/useVideos";
import { cn } from "@/lib/utils";
import { formatNumberToTime } from "@/utils/formatNumberToTime";
import { CorteDoVideo } from "@/models/CorteDoVideo";
import { VideoSourceType } from "@/models/VideoSourceType";
import { useLoaderContext } from "@/contexts/LoaderContext";

import "react-datepicker/dist/react-datepicker.css";
import { useTokenContext } from "@/contexts/TokenContext";

interface VideosModalProps {
  videoIndex: number;
  activeVideo: number;
  videoFullscreen: boolean;
  videoSource: VideoSourceType;
  cortesDosVideos: CorteDoVideo[];
  handlePlay: () => void;
  handlePause: () => void;
  handleVideoFullscreen: () => void;
  findCorteDoVideo: (urlVideo: string) => CorteDoVideo | null | undefined;
  findVideoPlayer: (urlVideo: string) => MutableRefObject<ReactPlayer | null>;
  handleSetCortesDosVideos: (corte: CorteDoVideo[]) => void;
  handleSetActiveVideo: () => void,
};

export default function VideoPlayerActions({
  videoIndex,
  activeVideo,
  videoFullscreen,
  videoSource,
  cortesDosVideos,
  handlePlay,
  handlePause,
  handleVideoFullscreen,
  findCorteDoVideo,
  findVideoPlayer,
  handleSetCortesDosVideos,
  handleSetActiveVideo,
}: VideosModalProps) {
  const { handleStartLoader, handleStopLoader } = useLoaderContext();
  const { token } = useTokenContext();

  const handleDownload = (event: FormEvent, source: string) => {
    event.preventDefault();

    handleStartLoader();
    handlePause();

    getVideosDownload(source, token)
      .then((response) => {
        handleStopLoader();
        response && window.open(response, '_blank', 'noopener,noreferrer');
      })
      .catch((error) => {
        handleStopLoader();
        console.log(error);
      });
  };

  const handleClickRecord = (urlVideo: string) => {
    const corteDoVideo = findCorteDoVideo(urlVideo);

    if (!corteDoVideo) {
      return;
    }

    const videoPlayer = findVideoPlayer(urlVideo);

    if (!videoPlayer || !videoPlayer?.current) {
      return;
    }

    const currentTime = formatNumberToTime(videoPlayer.current.getCurrentTime());
    const currentCortesDosVideos: CorteDoVideo[] = cortesDosVideos?.filter(
      (corte) => corte?.camera?.videoLink !== urlVideo,
    );

    if (!currentCortesDosVideos) {
      return;
    }

    if (corteDoVideo.acoes === 'gravar') {
      handlePlay();

      currentCortesDosVideos.push({
        ...corteDoVideo,
        inicioDoVideo: currentTime,
        acoes: 'gravando',
      });
    } else if (corteDoVideo.acoes === 'gravando') {
      handlePause();

      currentCortesDosVideos.push({
        ...corteDoVideo,
        fimDoVideo: currentTime,
        nomeDoVideo: `${
          corteDoVideo.nomeDoVideo
        }_de_${
          corteDoVideo.inicioDoVideo
        }_ate_${
          currentTime
        }`,
        acoes: 'exportar',
      });
    } else if (corteDoVideo.acoes === 'exportar') {
      handlePause();
      handleStartLoader();

      getGenerateClip(corteDoVideo, token)
        .then((response) => {
          const blob = new Blob([response], { type: 'Content-Disposition' });
          const element = document.createElement('a');

          element.href = URL.createObjectURL(blob);
          element.setAttribute('download', `${corteDoVideo.nomeDoVideo}.mp4`);
          document.body.appendChild(element);
          element.click();
          handleStopLoader();
        })
        .catch((error) => {
          handleStopLoader();
          console.log(error);
        });

      currentCortesDosVideos.push({
        ...corteDoVideo,
        inicioDoVideo: '00:00:00',
        fimDoVideo: '00:00:00',
        acoes: 'gravar',
      });
    }

    handleSetCortesDosVideos(currentCortesDosVideos);
  };

  const getCorteStatus = (urlVideo: string) => {
    const corteDoVideo = findCorteDoVideo(urlVideo);

    if (!corteDoVideo) {
      return null;
    }

    if (corteDoVideo.acoes === 'gravar') {
      return (
        <>
          <VideoIcon size={16} />
          {' '}
          Gravar
        </>
      );
    }

    if (corteDoVideo.acoes === 'gravando') {
      return (
        <>
          <VideoIcon className="text-red-500" size={16} />
          {' '}
          Gravando
        </>
      );
    }

    if (corteDoVideo.acoes === 'exportar') {
      return (
        <>
          <VideoIcon className="text-green-500" size={16} />
          {' '}
          Exportar
        </>
      );
    }

    return null;
  };

  return (
    <div
      className={cn(
        'flex justify-end gap-2 lg:gap-6 pr-1',
        activeVideo === videoIndex && videoFullscreen && 'fixed w-fit top-0 left-1/2 -translate-x-1/2 px-4 py-2 justify-center bg-black/50 z-40'
      )}
    >
      <button
        className="flex items-center gap-1 text-xs w-fit uppercase hover:underline hover:underline-offset-[3px]"
        onClick={() => handleClickRecord(videoSource.urlStream)}
      >
        {getCorteStatus(videoSource.urlStream)}
      </button>
      <button
        className="flex items-center gap-2 text-xs w-fit uppercase hover:underline hover:underline-offset-[3px]"
        onClick={(event) => {
          handleDownload(event, videoSource.bucketIdDoVideoOriginal)
        }}
      >
        <DownloadIcon size={16} />
        {' '}
        Download
      </button>
      <button
        className="flex items-center gap-2 text-xs w-fit uppercase hover:underline hover:underline-offset-[3px]"
        onClick={() => {
          handleSetActiveVideo();
          handleVideoFullscreen();
        }}
      >
        {!videoFullscreen ? (
          <>
            <MaximizeIcon size={16} />
            {' '}
            Tela cheia
          </>
        ) : (
          <>
            <MinimizeIcon size={16} />
            {' '}
            Minimizar
          </>
        )}
      </button>
    </div>
  );
};
