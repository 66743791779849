import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import VideosPlayer from "@/components/VideosPlayer";

import { useLoaderContext } from "@/contexts/LoaderContext";
import { getGenerateLinks, getListVideos } from "@/services/hooks/useVideos";
import { useToast } from "@/hooks/use-toast";
import { TVehiclesCamerasByDate } from "@/models/TVehiclesCamerasByDate";
import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";
import { useTokenContext } from "@/contexts/TokenContext/TokenContext";

export default function Video() {
  const navigate = useNavigate();
  const location = useLocation();

  const { toast } = useToast()
  
  const { startDate = '', veiculoId = '', startHour = '' } = useParams();
  const { handleStartLoader, handleStopLoader } = useLoaderContext();
  const { token, setToken } = useTokenContext();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [allVehicleVideos, setAllVehicleVideos] = useState<TVehiclesCamerasByDate[]>([]);
  const [selectedVehicleVideo, setSelectedVehicleVideo] = useState<VideoInputOutputDTO>();
  const [playbackRate, setPlaybackRate] = useState<number>(1);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlToken = searchParams.get('token') || '';

    if (urlToken) {
      setToken(urlToken);
  
      searchParams.delete('token');

      const newUrl = `${location.pathname}?${searchParams.toString()}`;

      window.history.replaceState(null, '', newUrl);
    } else if (!token) {
      setLoaded(true);
      toast({
        variant: "destructive",
        description: "Você não tem acesso ao vídeo ou suas credenciais expiraram.",
        duration: 1000 * 60 * 10,
      });
    }
  }, [location?.pathname, location?.search, setToken, token, toast]);

  const startMoment = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return parseFloat(searchParams?.get('startMoment') || '') || 0;
  }, [location.search]);

  useEffect(() => {
    if (!loaded) handleStartLoader();
  }, [handleStartLoader, loaded]);

  const getRecentVehicleVideos = useCallback(
    async () => {
      if (!veiculoId.length || !token?.length) return;

      try {
        const vehicleCamera = await getListVideos(
          veiculoId,
          startDate,
          token,
        );

        setLoaded(true);

        return vehicleCamera;
      } catch (error) {
        console.error(`Error fetching data for ${startDate}:`, error);

        if (!!toast) {
          if (!!handleStopLoader) handleStopLoader();
          
          setLoaded(true);
          toast({
            variant: "destructive",
            description: "Você não tem acesso ao vídeo ou suas credenciais expiraram.",
            duration: 1000 * 60 * 10,
          });
        }
      }
    },
    [handleStopLoader, startDate, toast, token, veiculoId]
  );

  const getSelectedVideoData = useCallback(
    async (selected: VideoInputOutputDTO | null) => {
      if (!selected) return null;

      try {
        const videoLinks = await getGenerateLinks(selected, token);

        return videoLinks;
      } catch (error) {
        console.error(`Error fetching data for: `, error);
      }

      return null;
    },
    [token],
  );

  useEffect(() => {
    if (loaded || !startDate || !veiculoId) return;

    const getVideos = async () => {
      const vehicleVideosData = await getRecentVehicleVideos();

      if (!!vehicleVideosData) {
        const videoDays = vehicleVideosData.reduce(
          (accumulator: string[] = [], _video: VideoInputOutputDTO) => (
            !accumulator.includes(_video.dataDoVideo)
              ? [...accumulator, _video.dataDoVideo]
              : accumulator
          ),
          [],
        );

        if (!!videoDays?.length) {
          const allVideos = videoDays.map((_date) => ({
            date: _date,
            vehicles: vehicleVideosData.filter(
              (_video) => _video.dataDoVideo === _date,
            ),
          }));

          setAllVehicleVideos(allVideos);
        }
      }
    };

    getVideos();
  }, [getRecentVehicleVideos, loaded, startDate, veiculoId]);

  const startDateVehicleVideos = useMemo(() => (
    allVehicleVideos.find((_video) => _video.date === startDate)
  ), [allVehicleVideos, startDate]);

  useEffect(() => {
    const selectedVehicleVideoData = !startHour?.length
      ? startDateVehicleVideos?.vehicles[0]
      : startDateVehicleVideos?.vehicles?.find(
        (_video) => (_video.horaDoVideo === startHour.replace('-', ':')),
      );

    if (!selectedVehicleVideoData) return;

    const fetchData = async () => {
      const videoData = await getSelectedVideoData(selectedVehicleVideoData);

      if (!!videoData) {
        setSelectedVehicleVideo(videoData);
      }
    };

    fetchData();
  }, [getSelectedVideoData, startDateVehicleVideos?.vehicles, startHour]);

  const handleClickPlaybackRate = () => {
    const allowed = [1, 2, 5, 10, 15];

    setPlaybackRate((previous) => (
      allowed[(allowed.indexOf(previous) + 1) % allowed.length]
    ));
  };

  const onVideoNavigation = (index: number) => {
    navigate(`/${
      veiculoId
    }/${
      startDate
    }/videos/${
      startDateVehicleVideos?.vehicles[index].horaDoVideo.replace(':', '-')
    }`);
  };

  const handleClickNextVideo = () => {
    if (!selectedVehicleVideo || !startDateVehicleVideos?.vehicles) return;

    const index = startDateVehicleVideos?.vehicles?.findIndex(
      (_vehicle) => _vehicle.id === selectedVehicleVideo?.id
    );

    if (index + 1 < startDateVehicleVideos?.vehicles?.length) {
      onVideoNavigation(index + 1);
    }
  };

  const handleClickPreviousVideo = () => {
    if (!selectedVehicleVideo || !startDateVehicleVideos?.vehicles) return;

    const index = startDateVehicleVideos?.vehicles?.findIndex(
      (_vehicle) => _vehicle.id === selectedVehicleVideo?.id
    );

    if (index - 1 >= 0) {
      onVideoNavigation(index - 1);
    }
  };

  return (
    <>
      {!!selectedVehicleVideo && !!startDateVehicleVideos?.vehicles
        ? (
          <>
            <VideosPlayer
              key={`${startDate}-${veiculoId}-${startHour}`}
              veiculoId={veiculoId}
              selectedVideo={selectedVehicleVideo}
              videoStart={startMoment}
              videosList={startDateVehicleVideos?.vehicles}
              playbackRate={playbackRate}
              handleClickPlaybackRate={handleClickPlaybackRate}
              handleClickNextVideo={handleClickNextVideo}
              handleClickPreviousVideo={handleClickPreviousVideo}
            />
          </>
        ) : (
          <div className="w-full h-screen bg-black" />
        )}
    </>
  );
};
