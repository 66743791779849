import { Route, Routes } from "react-router-dom";

import Home from "@/views/Home";
import Video from "@/views/Video";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/:veiculoId/:startDate/videos/:startHour"
        element={<Video />}
      />
    </Routes>
  );
};
