import { Dispatch, SetStateAction } from "react";
import * as Slider from "@radix-ui/react-slider";
import {
  ChevronsLeftIcon,
  ChevronsRightIcon,
  ClockIcon,
  PauseIcon,
  PlayIcon,
} from "lucide-react";

import { formatVideoDuration } from "@/utils/formatVideoDuration";
import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";

import "react-datepicker/dist/react-datepicker.css";
import Tooltip from "@/components/Tooltip";
import { formatTimeMMSS } from "@/utils/formatTimeMMSS";

interface VideosModalProps {
  selectedVideo: VideoInputOutputDTO;
  played: number;
  duration: number;
  playing: boolean;
  playbackRate: number;
  videoStart: number;
  handleSetPlayed: (value: number) => void;
  handleSliderSeek: (value: number) => void;
  handleSetPlaying: Dispatch<SetStateAction<boolean>>;
  handleSeek: (value: number) => void;
  handleSetVideoTime: (value: number) => void;
  handleClickPlaybackRate: () => void;
};

export default function VideoPlayerControls({
  played,
  duration,
  playing,
  playbackRate,
  videoStart,
  handleSetPlayed,
  handleSliderSeek,
  handleSetPlaying,
  handleSeek,
  handleSetVideoTime,
  handleClickPlaybackRate,
}: VideosModalProps) {
  const onValueChange = (value: number[]) => handleSetPlayed(value[0]);

  const onValueCommit = (value: number[]) => handleSliderSeek(value[0]);

  const onSwitchPlaying = () => handleSetPlaying((previous) => !previous);

  const onSeekPrevious = () => handleSeek(-10);
  
  const onSeekNext = () => handleSeek(10);

  return (
    <div className="grid grid-cols-12 gap-2 w-full">
      <div className="flex justify-center items-center text-sm col-span-2 lg:col-span-1 lg:text-lg">
        {formatVideoDuration(duration * played)}
      </div>
      <div className="ml-auto w-full h-8 flex items-center col-span-8 lg:h-16 lg:col-span-6">
        <Slider.Root
          className="relative select-none w-full"
          value={[played]}
          max={1}
          step={0.001}
          onValueChange={onValueChange}
          onValueCommit={onValueCommit}
        >
          <Slider.Track
            className="block relative flex-grow rounded-full bg-gray-500 w-full h-2"
          >
            <Slider.Range className="absolute bg-white rounded-l-full h-full" />
          </Slider.Track>
          <Slider.Thumb
            className="block relative w-5 h-5 bg-white rounded-full -translate-y-1/2 -top-1 hover:bg-chart-200 focus:outline-none focus:shadow-md"
            aria-label="Tempo do vídeo"
          />
          {!!videoStart ? (
            <>
              <Tooltip
                content={`Tempo: ${formatTimeMMSS(videoStart)}`}
                icon={<ClockIcon size="18" />}
                color="bg-sky-500"
              >
                <button
                  className="absolute top-0 w-4 h-4 -translate-x-[calc(75%-3px)] -translate-y-1 rounded-full bg-sky-500 hover:ring-2 hover:ring-sky-500/50 transition-colors"
                  style={{ left: `calc(${(videoStart / duration) * 100}% + 3px)`}}
                  onPointerDown={(event) => {
                    event.stopPropagation();
                    handleSetVideoTime(videoStart / duration);
                  }}
                />
              </Tooltip>
            </>            
          ) : null}
        </Slider.Root>
      </div>
      <div className="flex justify-center items-center text-sm col-span-2 lg:col-span-1 lg:text-lg">
        {formatVideoDuration(duration)}
      </div>
      <div className="col-span-3 px-2 lg:col-span-1 flex justify-center items-center">
        <button
          className="block w-8 aspect-square mx-auto"
          onClick={onSwitchPlaying}
        >
          {!playing ? (
            <PlayIcon className="block mx-auto" size={36} />
          ) : (
            <PauseIcon className="block mx-auto" size={36} />
          )}
        </button>
      </div>
      <div className="col-span-3 px-2 lg:col-span-1 flex justify-center items-center">
        <button
          className="block w-8 aspect-square mx-auto"
          onClick={onSeekPrevious}
        >
          <ChevronsLeftIcon className="block mx-auto" size={36} />
        </button>
      </div>
      <div className="col-span-3 px-2 lg:col-span-1 flex justify-center items-center">
        <button
          className="block w-8 aspect-square mx-auto"
          onClick={onSeekNext}
        >
          <ChevronsRightIcon className="block mx-auto" size={36} />
        </button>
      </div>
      <div className="col-span-3 px-2 lg:col-span-1 flex justify-center items-center">
        <button
          className="block w-8 aspect-square mx-auto"
          onClick={handleClickPlaybackRate}
        >
          <span className="text-2xl">{playbackRate}x</span>
        </button>
      </div>
    </div>
  );
};
