import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CircleXIcon } from "lucide-react";

import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";

import { cn } from "@/lib/utils";
import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";

import "react-datepicker/dist/react-datepicker.css";

interface VideosModalProps {
  videos?: VideoInputOutputDTO[];
  videoIndex?: number;
  handleClosePlaylist?: () => void;
};

export default function Playlist({
  videos = [],
  videoIndex = -1,
  handleClosePlaylist,
}: VideosModalProps) {
  const navigate = useNavigate();

  const scrollAreaRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollAreaRef.current && videoIndex >= 0) {
      const videoButton = scrollAreaRef.current.querySelector(
        `button[data-index="${videoIndex}"]`,
      );
      if (videoButton) {
        videoButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [videoIndex]);

  return (
    <div className="relative w-full max-h-[50vh] lg:max-h-screen lg:h-screen bg-neutral-800 z-20">
      <ScrollArea
        ref={scrollAreaRef}
        className="w-full h-full bg-neutral-800 p-0"
        type="scroll"
      >
        <div className="w-full flex lg:flex-col pt-20 pb-12 lg:pt-16 lg:pb-0 gap-6 px-6 lg:px-1">
          {videos.map((video, index) => {
            return (
              <button
                key={video.id}
                data-index={index + 1}
                className="w-32 lg:w-full lg:pb-6 lg:pl-12 lg:pr-16"
                onClick={() => {
                  navigate(`/${
                    video.veiculoId
                  }/${
                    video.dataDoVideo
                  }/videos/${
                    video.horaDoVideo.replace(':', '-')
                  }`);
                }}
              >
                <div className={cn(
                  'ring-2 ring-offset-4 ring-offset-neutral-800 overflow-hidden rounded-lg',
                  videoIndex === index + 1 && 'ring-white',
                  videoIndex !== index + 1 && 'ring-transparent',
                )}>
                  <img src={video.linkDaThumbnail} alt="Thumbnail" />
                </div>
                <div className="flex justify-between items-center mt-3">
                  <p className="text-sm">
                    {video?.inicioDoVideo?.substring(0, 5) || ''}
                    {' – '}
                    {video?.fimDoVideo?.substring(0, 5) || ''}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
        <ScrollBar orientation="vertical" />
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
        <button
          className="w-fit absolute top-6 right-6 text-neutral-500 hover:text-white transition-colors z-20"
          onClick={handleClosePlaylist}
        >
          <CircleXIcon />
        </button>
    </div>
  );
};
