import { padNumber } from "@/utils/padNumber";

export function formatVideoDuration(seconds: number): string {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = padNumber(`${date.getUTCSeconds()}`);

  return `${padNumber(`${(hh / 60) + mm}`)}:${ss}`;
};
