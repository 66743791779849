import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 5000 * 60
});

instance.interceptors.response.use(
  response => {
    return response;
  },
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // if (error?.response?.status === 401) {
    //   setTimeout(() => {
    //     window.location.pathname = "/";
    //   }, 3000);
    // }

    return Promise.reject(error);
  },
);

export { instance as http };
