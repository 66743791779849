import { useQuery } from "react-query";

import { http } from "@/services/useAxios";

export async function getVideosDownload(
  bucketId: string,
  token: string,
): Promise<string> {
  const response = await http.get(
    `Videos/Download/${bucketId}`,
    {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  
  const textData = await response.data.text();

  return textData as string;
};

export function useVideosDownload(bucketId: string, token: string) {
  return useQuery(
    `videos-download-${bucketId}`,
    () => getVideosDownload(bucketId, token),
    {
      staleTime: 1000 * 60 * 60,
      enabled: true,
    },
  );
};
