import { BrowserRouter } from "react-router-dom";

import { Router } from "@/routes/Router";
import { LoaderProvider } from "@/contexts/LoaderContext";

import { Toaster } from "@/components/ui/toaster";
import { TokenProvider } from "./contexts/TokenContext/TokenContext";

export default function App() {
  return (
    <BrowserRouter>
      <LoaderProvider>
        <TokenProvider>
          <div>
            <Router />
            <Toaster />
          </div>
        </TokenProvider>
      </LoaderProvider>
    </BrowserRouter>
  );
};
