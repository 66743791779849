import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ListVideoIcon,
  LoaderCircleIcon,
  XCircleIcon,
  XIcon,
} from "lucide-react";

import Playlist from "@/components/Playlist";
import VideoPlayerActions from "@/components/VideosPlayer/components/VideoPlayerActions";
import VideoPlayerControls from "@/components/VideosPlayer/components/VideoPlayerControls";
import VideoTimelapseControl from "@/components/VideosPlayer/components/VideoTimelapseControl";

import { useLoaderContext } from "../../contexts/LoaderContext";
import { cn } from "../../lib/utils";
import { formatDateDDMMYY } from "@/utils/formatDateDDMMYY";
import { CameraInputOutputDTO } from "@/models/CameraInputOutputDTO";
import { CorteDoVideo } from "@/models/CorteDoVideo";
import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";
import { VideoSourceType } from "@/models/VideoSourceType";

import "react-datepicker/dist/react-datepicker.css";

interface VideosPlayerProps {
  playbackRate: number;
  selectedVideo: VideoInputOutputDTO;
  videoStart?: number;
  videosList: VideoInputOutputDTO[];
  veiculoId: string;
  handleClickNextVideo: () => void;
  handleClickPlaybackRate: () => void;
  handleClickPreviousVideo: () => void;
};

export default function VideosPlayer({
  playbackRate,
  selectedVideo,
  videoStart = 0,
  videosList,
  veiculoId,
  handleClickNextVideo,
  handleClickPlaybackRate,
  handleClickPreviousVideo,
}: VideosPlayerProps) {
  const navigate = useNavigate();

  const { handleStartLoader, handleStopLoader } = useLoaderContext();

  const videoPlayers = [
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null)
  ];

  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0);
  const [videoGuideKey, setVideoGuideKey] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [activeVideo, setActiveVideo] = useState<number>(0);
  const [showPlaylist, setShowPlaylist] = useState<boolean>(false);
  const [cortesDosVideos, setCortesDosVideos] = useState<CorteDoVideo[]>([]);
  const [videosSources, setVideosSources] = useState<VideoSourceType[]>([]);
  const [videoFullscreen, setVideoFullscreen] = useState<boolean>(false);
  const [bufferEnded, setBufferEnded] = useState<boolean>(false);
  const [visibleControl, setVisibleControl] = useState<boolean>(true);
  const [shouldRemount, setShouldRemount] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const remount = () => {
      setShouldRemount((prev) => !prev);
    };

    if (!bufferEnded) {
      timer = setTimeout(remount, 1000 * 5);
    }

    return () => { clearTimeout(timer) };
  }, [bufferEnded]);

  useEffect(() => {
    if (shouldRemount) {
      setShouldRemount(false);
    }
  }, [shouldRemount]);

  const feedReference = (player: ReactPlayer, index: number) => {
    videoPlayers[index].current = player;
  };

  const feedCorteCamera = (
    videoLinks: VideoInputOutputDTO,
    camera: CameraInputOutputDTO,
    cameraNumero: number
  ): CorteDoVideo => ({
      camera: camera,
      inicioDoVideo: '00:00:00',
      fimDoVideo: '00:00:00',
      nomeDoVideo: `camera${
        cameraNumero
      }_${
        videoLinks.veiculoNome
      }_${
        videoLinks.dataDoVideo
      }_${
        videoLinks.horaDoVideo
      }`,
      acoes: 'gravar',
  });

  useEffect(() =>  {
    if (!handleStartLoader) {
      return;
    }
  }, [handleStartLoader]);

  useEffect(() => {
    const currentSources: VideoSourceType[] = [];
    const currentCortes: CorteDoVideo[] = [];

    if (selectedVideo) {
      const updateSources = (camera: CameraInputOutputDTO, cameraNumber: number) => {
        if (camera?.videoLink && camera.bucketIdDoVideoOriginal) {
          currentSources.push({
            videoId: camera.videoId,
            urlStream: camera.videoLink,
            bucketIdDoVideoOriginal: camera.bucketIdDoVideoOriginal,
            timeLapse: [],
          });

          currentCortes.push(feedCorteCamera(selectedVideo, camera, cameraNumber));
        }
      };

      if (selectedVideo?.camera1) {
        updateSources(selectedVideo?.camera1, 1)
      }

      if (selectedVideo?.camera2) {
        updateSources(selectedVideo?.camera2, 2);
      }

      if (selectedVideo?.camera3) {
        updateSources(selectedVideo?.camera3, 3);
      }

      if (selectedVideo?.camera4) {
        updateSources(selectedVideo?.camera4, 4);
      }

      setVideosSources(currentSources);
      setCortesDosVideos(currentCortes);
    }
  }, [selectedVideo]);

  useEffect(() => {
    const timer = setTimeout(() => { setVisibleControl(false) }, 1000 * 5);
  
    const handleUserActivity = () => {
      if (!visibleControl) {
        setVisibleControl(true);
        clearTimeout(timer);
        setTimeout(() => { setVisibleControl(false) }, 5000);
      }
    };
  
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
  
    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [visibleControl]);

  const beginnerAction = () => { setPlaying(false) }

  const syncVideos = (shouldPlay: boolean) => {
    beginnerAction();

    if (!shouldPlay) {
      setPlaying(shouldPlay);

      return;
    }

    if (!videoPlayers[videoGuideKey]) {
      beginnerAction();

      return;
    }

    const currentTime = videoPlayers[videoGuideKey].current!.getCurrentTime();

    videoPlayers.forEach((player, index) => {
      const videoDuration = player?.current?.getDuration() || 0;

      if (currentTime < videoDuration) {
        player.current?.seekTo(currentTime, 'seconds');
      } else {
        player.current?.seekTo(videoDuration - 0.5, 'seconds');
      }

      if (index >= videoPlayers?.length - 1) {
        setTimeout(function () {
          setPlaying(shouldPlay);
        }, 500);
      }
    });
  };

  const handlePlay = () => {
    setPlaying(false);
    syncVideos(true);
  };

  const handlePause = () => {
    setPlaying(false);
    syncVideos(false);
  };

  const handlePlaybackRateChange = (speed: number) => {
    beginnerAction();

    if (!videoPlayers[0]) {
      syncVideos(false);

      return;
    }

    const currentTime = videoPlayers[0].current!.getCurrentTime();

    videoPlayers[0].current!.seekTo(speed, 'seconds');
    videoPlayers.forEach((player) => {
      player.current?.seekTo(currentTime, 'seconds');
    });

    syncVideos(true);
  };

  const handleSetVideoTime = (value: number) => {
    if (value < 0) {
      value = 0;
    }

    if (value > 0.999999) {
      value = 0.999999;
    }

    setPlayed(value);
    handleSliderSeek(value);
    setPlaying(false);
    handlePause();
  };

  const handleSeek = (seek: number) => {
    setPlaying(false);

    if (!videoPlayers[videoGuideKey]) {
      return;
    }

    const currentTime = videoPlayers[videoGuideKey].current!.getCurrentTime();

    videoPlayers[videoGuideKey]!.current!.seekTo(
      currentTime + (seek * playbackRate),
      'seconds',
    );
    syncVideos(true);
  };

  const handleSliderSeek = (seek: number) => {
    setPlaying(false);

    if (!videoPlayers[videoGuideKey]) {
      return;
    }

    videoPlayers[videoGuideKey]!.current!.seekTo(seek, 'fraction');
    syncVideos(true);
    setPlaying(false);
  };

  const handleOnReady = (index: number) => {
    if (index !== videoGuideKey) {
      return;
    }

    selectedVideo && handleSeek(0);
    syncVideos(false);
  };

  const findCorteDoVideo = (urlVideo: string): CorteDoVideo | null | undefined => {
    return cortesDosVideos?.filter(c => c.camera.videoLink === urlVideo)[0]
  };

  const findVideoPlayer = (urlVideo: string): React.MutableRefObject<ReactPlayer | null> => {
    return videoPlayers.filter(c => c.current?.props.url === urlVideo)[0]
  };

  const selectedVideoIndex = useMemo(() => (
    videosList.findIndex((_video) => _video.id === selectedVideo.id) + 1
  ), [selectedVideo.id, videosList]);

  return (
    <div
      key={shouldRemount ? "remount" : "original"}
      className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black text-white z-10"
    >
      {videoFullscreen && !visibleControl && (
        <div className="fixed top-0 left-0 w-full h-screen bg-transparent z-30 cursor-none" />
      )}
      <Link
        className="w-fit fixed top-6 right-6 text-neutral-500 hover:text-white transition-colors"
        to={`/veiculos/${veiculoId}`}
      >
        <XCircleIcon size={32} />
      </Link>
      <div className={cn(
        'flex flex-col lg:transition-all lg:ease-linear h-screen',
        showPlaylist && 'flex-grow w-0 overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:w-[calc(100%-320px)] lg:duration-500',
        !showPlaylist && 'w-full lg:duration-300',
        videosSources.length < 4 ? 'py-24 lg:py-8' : 'py-24 lg:py-4',
      )}>
        <div
          className={cn(
            'w-full space-y-1.5 mt-2 order-2 lg:order-2 lg:grid lg:grid-cols-3 lg:px-12',
            videosSources.length < 4 ? 'lg:mb-16' : 'lg:mb-4',
          )}
        >
          <div className={cn(
            'w-full flex justify-center items-center gap-2 text-center',
            'lg:col-span-3 lg:relative',
          )}>
            <img
              className={cn(
                'block h-8 absolute top-6 left-1/2 -translate-x-1/2',
                'lg:h-10 lg:top-0 lg:left-0 lg:translate-x-0',
              )}
              src="/logo-digefx.png"
              alt="DIGEFx" />
            <button
              className="disabled:text-white/25 disabled:cursor-not-allowed"
              disabled={selectedVideoIndex === 1}
              onClick={handleClickPreviousVideo}
            >
              <ChevronLeftIcon size={24} />
            </button>
            <h2 className="min-w-40 max-w-40 text-xl">
              Gravação: {selectedVideoIndex}/{videosList.length}
            </h2>
            <button
              className="disabled:text-white/25 disabled:cursor-not-allowed"
              disabled={selectedVideoIndex === videosList.length}
              onClick={handleClickNextVideo}
            >
              <ChevronRightIcon size={24} />
            </button>
          </div>
          <div className="text-center text-sm lg:col-span-3 pb-2">
            {selectedVideo?.inicioDoVideo?.substring(0, 5) || ''}
            {' – '}
            {selectedVideo?.fimDoVideo?.substring(0, 5) || ''}
          </div>
          <div className="absolute top-6 left-6 text-xs lg:relative lg:top-auto lg:left-auto xl:text-base">
            <p>VEÍCULO: {selectedVideo.veiculoNome}</p>
            <p>DATA: {formatDateDDMMYY(selectedVideo.dataDoVideo)}</p>
            <p>HORA: {selectedVideo.horaDoVideo}</p>
            {!!selectedVideo.localidade?.length && (
              <p>BASE: {selectedVideo.localidade.replace(/.+ - /g, '')}</p>
            )}
          </div>
          <div className="w-full h-fit flex justify-center" />
          <div className="w-full h-fit flex justify-center lg:justify-end">
            <button
              className={cn(
                'w-full max-w-64 px-3 py-2 flex rounded-2xl border whitespace-nowrap',
                'border-white',
              )}
              onClick={() => setShowPlaylist((previous) => !previous)}
            >
              {showPlaylist ? (
                <XIcon className="block mr-2" size={24} />
              ) : (
                <ListVideoIcon className="block mr-2" size={24} />
              )}
              {' '}
              <div className="w-full bg-black">
                {showPlaylist ? 'Fechar' : 'Abrir'} Playlist
              </div>
            </button>
          </div>
        </div>
        <div className="w-full mt-2 lg:mb-0 order-1 lg:order-2">
          {!!videosSources.length && (
            <div className={cn(
              'w-full mb-4 grid',
              videosSources.length === 1 && 'grid-cols-1',
              videosSources.length === 2 && 'grid-cols-2',
              videosSources.length === 3 && 'grid-cols-3',
              videosSources.length === 4 && 'grid-cols-4',
              'lg:hidden',
            )}>
              {videosSources.map((source, index) => (
                <div key={source.videoId} className="text-center">
                  <button
                    className={cn(
                      'text-lg',
                      activeVideo === index ? 'opacity-100' : 'opacity-50',
                    )}
                    onClick={() => setActiveVideo(index)}
                  >
                    CAM {index + 1}
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className={cn(
            'relative w-full aspect-video px-8',
            'lg:grid lg:gap-6 lg:aspect-auto lg:mx-auto lg:px-12',
            [1, 3].includes(videosSources.length) && 'lg:grid-cols-3',
            [2, 4].includes(videosSources.length) && 'lg:grid-cols-6',
            [1, 2, 3].includes(videosSources.length) && 'lg:mb-16',
            videosSources.length === 4 && ' lg:mb-6 lg:gap-y-4',
          )}>
            {videosSources.map((source, index) => {
              const isActive = activeVideo === index;
              const bufferEnd = bufferEnded;

              return (
                <div
                  key={source.videoId}
                  className={cn(
                    'lg:relative lg:top-auto lg:left-auto lg:right-auto lg:w-fit lg:mx-auto lg:max-w-full',
                    index > 0 && 'absolute top-0 left-8 right-8',
                    index === 0 && 'relative',
                    isActive && 'z-30',
                    !isActive && 'z-10',
                    videosSources.length === 1 && 'lg:col-start-2',
                    [2, 4].includes(videosSources.length) && 'lg:col-span-2',
                    [2, 4].includes(videosSources.length) && index % 2 === 0 && `lg:col-start-2`
                  )}
                >
                  <div
                    className={cn(
                      'relative w-full p-1 ring-4 ring-transparent lg:transition-all aspect-video',
                      'lg:h-auto lg:w-fit lg:mx-auto',
                      bufferEnd && isActive && !videoFullscreen && 'ring-white rounded-xs',
                      bufferEnd && isActive && videoFullscreen && 'fixed w-full h-screen top-0 left-0 p-0 bg-black z-30',
                      activeVideo === index && videoFullscreen
                        ? 'min-w-full h-screen'
                        : videosSources.length < 4
                        ? 'lg:max-h-[30vh]'
                        : 'lg:max-h-[22vh]'
                    )}
                  >
                    <button
                      className={cn(
                        'block',
                        !bufferEnd && 'h-0 overflow-hidden',
                        activeVideo === index && videoFullscreen && 'mx-auto'
                      )}
                      onClick={() => setActiveVideo(index)}
                    >
                      <ReactPlayer
                        ref={(player) => feedReference(player!, index)}
                        playsInline
                        style={{}}
                        height={activeVideo === index && videoFullscreen ? '100vh' : 'auto'}
                        width={activeVideo === index && videoFullscreen ? 'auto' : '100%'}
                        url={source.urlStream}
                        playing={playing}
                        playbackRate={playbackRate}
                        muted={true}
                        onPlay={() => handlePlay}
                        onPause={() => handlePause}
                        onPlaybackRateChange={handlePlaybackRateChange}
                        onDuration={(d: number) => {
                          if (d > duration) {
                            setDuration(d);
                            setVideoGuideKey(index);

                            if (!!videoStart) {
                              handleSetVideoTime(videoStart / (d || 1) || 0);
                            }
                          }
                        }}
                        onProgress={(p) => {
                          if (index === videoGuideKey) {
                            setPlayed(p.played)
                          }
                        }}
                        onEnded={() => {
                          if (index === videoGuideKey && selectedVideoIndex < videosList.length) {
                            navigate(`/${
                              videosList[selectedVideoIndex].veiculoId
                            }/${
                              videosList[selectedVideoIndex].dataDoVideo
                            }/videos/${
                              videosList[selectedVideoIndex].horaDoVideo.replace(':', '-')
                            }`);
                          }
                        }}
                        onReady={() => handleOnReady(index)}
                        onBufferEnd={() => {
                          if (index === videoGuideKey) {
                            handleStopLoader();
                            setBufferEnded(true);
                          }
                        }}
                        config={{ file: { forceHLS: true } }}
                      />
                    </button>
                    {!bufferEnd && (
                      <div className="w-full aspect-video flex justify-center items-center bg-neutral-600 text-white">
                        <LoaderCircleIcon className="animate-spin" size={64} />
                      </div>
                    )}
                  </div>
                  <div
                    className={cn(
                      'hidden',
                      'lg:flex justify-end gap-6 pr-1 mt-4',
                      activeVideo === index && 'flex',
                      activeVideo === index && videoFullscreen && 'fixed w-fit top-0 left-1/2 -translate-x-1/2 py-2 justify-center bg-black/50 z-40',
                      videoFullscreen && visibleControl && 'opacity-100',
                      videoFullscreen && !visibleControl && 'opacity-0',
                    )}
                  >
                    <VideoPlayerActions
                      videoIndex={index}
                      activeVideo={activeVideo}
                      videoFullscreen={videoFullscreen}
                      videoSource={source}
                      cortesDosVideos={cortesDosVideos}
                      handlePlay={handlePlay}
                      handlePause={handlePause}
                      handleVideoFullscreen={() => setVideoFullscreen((previous) => !previous)}
                      findCorteDoVideo={findCorteDoVideo}
                      findVideoPlayer={findVideoPlayer}
                      handleSetCortesDosVideos={setCortesDosVideos}
                      handleSetActiveVideo={() => setActiveVideo(index)}
                    />
                  </div>
                </div>
              );
            })}
            <div className={cn(
              activeVideo !== 0 && 'mt-8',
              videoFullscreen && visibleControl && 'opacity-100',
              videoFullscreen && !visibleControl && 'opacity-0',
              [1, 3].includes(videosSources.length) && 'lg:col-span-3',
              [2, 4].includes(videosSources.length) && 'lg:col-span-6',
            )}>
              <VideoTimelapseControl
                played={played}
                duration={duration}
                videoFullscreen={videoFullscreen}
                activeVideo={activeVideo}
                timeLapse={videosSources?.[activeVideo]?.timeLapse || []}
                handleSliderSeek={handleSliderSeek}
              />
            </div>
            <div
              className={cn(
                'flex justify-between items-center gap-8 px-5',
                'lg:px-0 ',
                videoFullscreen && 'fixed w-1/2 bottom-24 lg:bottom-32 left-1/2 -translate-x-1/2 justify-center bg-black/50 z-40',
                videoFullscreen && visibleControl && 'opacity-100',
                videoFullscreen && !visibleControl && 'opacity-0',
                [1, 3].includes(videosSources.length) && 'lg:col-span-3',
                [2, 4].includes(videosSources.length) && 'lg:col-span-6',
              )}
            >
              <VideoPlayerControls
                selectedVideo={selectedVideo}
                played={played}
                duration={duration}
                playing={playing}
                playbackRate={playbackRate}
                videoStart={videoStart}
                handleSetPlayed={setPlayed}
                handleSliderSeek={handleSliderSeek}
                handleSetPlaying={setPlaying}
                handleSeek={handleSeek}
                handleSetVideoTime={handleSetVideoTime}
                handleClickPlaybackRate={handleClickPlaybackRate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={cn(
        'fixed w-full h-fit z-20 -bottom-[calc(100%+4px)] transition-all',
        'lg:relative lg:bottom-0 lg:h-full lg:overflow-hidden',
        showPlaylist && 'bottom-0 duration-500 ease-out lg:w-[320px]',
        !showPlaylist && 'duration-300 ease-linear lg:w-0',
      )}>
        <div className="w-full lg:w-[320px]">
          <Playlist
            videos={videosList}
            videoIndex={selectedVideoIndex}
            handleClosePlaylist={() => setShowPlaylist(false)}
          />
        </div>
      </div>
    </div>
  );
};
